@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");

.divpsn {
    position: relative;
}


#togglebtnposition {
    position: absolute;
    right: 15px;
    top: 18px;
    cursor: pointer;
}


#backcolor {
    background-color: #0093e9;
    background-image: linear-gradient(160deg, rgb(150 109 215) 0%, rgb(128, 208, 199) 100%);
    font-family: "Alkatra", cursive;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .outercard {
    border-radius: 3%;
    padding: 15px;
    background-color: black;
    margin: 5%;
  }
  