/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f7f8;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'Lato', sans-serif;
}
